<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return">
					<el-button
						type="text"
						size="small"
						@click="back"
						>返回</el-button>
				</p>
				<div class="h-b">
					<el-button
						type="primary"
						size="small"
						@click="add"
						v-right-code="'Tenantsystem:Save'"
						>新增</el-button
					>
				</div>
				<div class="h-b">
					<el-button
						type="text"
						size="small"
						@click="save"
						v-right-code="'Tenantsystem:Save'"
						>保存</el-button
					>
				</div>
			</div>
		</div>

		<div class="form-list">
			<el-form
				:model="dataSource"
				ref="basicForm"
				:rules="baseCheckRule"
			>
						<el-form-item>
							<el-col :span="3" class="form-title" ><span style="color: red">*</span> 租户系统名称：</el-col>
							<el-col :span="9">
								<el-form-item prop="TenantSystemName">
                                    <el-input v-model="dataSource.TenantSystemName" :maxlength="200"></el-input>
                                </el-form-item>
							</el-col>
						</el-form-item>
                        
                        <el-form-item>
							<el-col :span="3" class="form-title" ><span style="color: red">*</span> 租户：</el-col>
							<el-col :span="9">
								<el-form-item prop="TenantName">
                                    <LookPopup v-model="dataSource.TenantName"
                                        dataTitle="中间件服务器"
                                        dataUrl='omsapi/optenterprise/getlist'
                                        syncField='EnterpriseName'
                                        @syncFieldEvent="syncTenant"
                                    ></LookPopup>
                                </el-form-item>
							</el-col>
							<el-col :span="3" class="form-title"> 租户系统编码：</el-col>
							<el-col :span="9">
								<el-form-item prop="TenantCode">
                                    <el-input v-model="dataSource.TenantCode" :disabled="true"></el-input>
                                </el-form-item>
							</el-col>
						</el-form-item>

                        <el-form-item>
							<el-col :span="3" class="form-title" ><span style="color: red">*</span> 应用：</el-col>
							<el-col :span="9">
								<el-form-item prop="TenantProductName">
                                    <LookPopup v-model="dataSource.TenantProductName"
                                        dataTitle="选择应用"
                                        dataUrl='omsapi/tenantproduct/getlist'
                                        syncField='ChineseName'
                                        @syncFieldEvent="syncTenantProduct"
                                    ></LookPopup>
                                </el-form-item>
							</el-col>
							<el-col :span="3" class="form-title"> 系统类型：</el-col>
							<el-col :span="9">
								<el-form-item prop="SystemType">
                                    <DictionarySelect 
										v-model="dataSource.SystemType" 
										datType='Int' 
										dicTypeCode="SystemTypeCode" :disabled="true">
									</DictionarySelect>
                                </el-form-item>
							</el-col>
						</el-form-item>

                        <el-form-item>
							<el-col :span="3" class="form-title" > 关联系统管理：</el-col>
							<el-col :span="9">
								<el-form-item prop="BasicTenantSystemName">
                                    <LookPopup v-model="dataSource.BasicTenantSystemName"
                                        dataTitle="选择系统"
                                        dataUrl='omsapi/tenantsystem/getlist'
                                        syncField='TenantSystemName'
                                        @syncFieldEvent="syncBasicTenantSystem"
                                    ></LookPopup>
                                </el-form-item>
							</el-col>
							<el-col :span="3" class="form-title" > 关联主数据管理：</el-col>
							<el-col :span="9">
								<el-form-item prop="BasicDataTenantSystemName">
                                    <LookPopup v-model="dataSource.BasicDataTenantSystemName"
                                        dataTitle="选择系统"
                                        dataUrl='omsapi/tenantsystem/getlist'
                                        syncField='TenantSystemName'
                                        @syncFieldEvent="syncBasicDataTenantSystem"
                                    ></LookPopup>
                                </el-form-item>
							</el-col>
						</el-form-item>

                        <el-form-item>
							<el-col :span="3" class="form-title" ><span style="color: red">*</span> 系统版本号：</el-col>
							<el-col :span="9">
								<el-form-item prop="SystemVersion">
                                    <el-input v-model="dataSource.SystemVersion"></el-input>
                                </el-form-item>
							</el-col>
                            <el-col :span="3" class="form-title"><span style="color: red">*</span> 域名：</el-col>
							<el-col :span="9">
								<el-form-item prop="Domain">
                                    <el-input v-model="dataSource.Domain" :maxlength="32"></el-input>
                                </el-form-item>
							</el-col>
						</el-form-item>
                        
                        <el-form-item>
							<el-col :span="3" class="form-title" > 最大仓库数：</el-col>
							<el-col :span="9">
								<el-form-item prop="MaxWareHouseNum">
                                    <el-input v-model="dataSource.MaxWareHouseNum" type="number"></el-input>
                                </el-form-item>
							</el-col>
                            <el-col :span="3" class="form-title"> 最大用户数：</el-col>
							<el-col :span="9">
								<el-form-item prop="MaxUserNum">
                                    <el-input v-model="dataSource.MaxUserNum" type="number"></el-input>
                                </el-form-item>
							</el-col>
						</el-form-item>

                        <el-form-item>
                            <el-col :span="3" class="form-title"><span style="color: red">*</span> 生效时间：</el-col>
							<el-col :span="9">
								<el-form-item prop="EffectiveDate">
                                    <el-date-picker v-model.trim="dataSource.EffectiveDate" placeholder="选择日期"
                                        type="date" value-format="yyyy-MM-dd HH:mm:ss">
                                    </el-date-picker>
                                </el-form-item>
							</el-col>
							<el-col :span="3" class="form-title" ><span style="color: red">*</span> 失效时间：</el-col>
							<el-col :span="9">
								<el-form-item prop="ExpireDate">
                                    <el-date-picker v-model.trim="dataSource.ExpireDate" placeholder="选择日期"
                                        type="date" value-format="yyyy-MM-dd HH:mm:ss">
                                    </el-date-picker>
                                </el-form-item>
							</el-col>
						</el-form-item>

                        <el-form-item>
							<el-col :span="3" class="form-title" > 服务商编码：</el-col>
							<el-col :span="9">
								<el-form-item prop="MemberCode">
                                    <el-input v-model="dataSource.MemberCode" :maxlength="32"></el-input>
                                </el-form-item>
							</el-col>
						</el-form-item>

                        <!-- <el-form-item>
							<el-col :span="3" class="form-title" ><span style="color: red">*</span> Mysql主数据库中间件：</el-col>
							<el-col :span="9">
								<el-form-item prop="MysqlMasterMiddlewareServerID">
                                    <LookPopup v-model="dataSource.MysqlMasterMiddlewareServerName"
                                        dataTitle="中间件服务器"
                                        dataUrl='omsapi/tenantserver/getlist'
                                        :dataOptions="{DisplayMiddlerwareType:100}"
                                        syncField='Name'
                                        @syncFieldEvent="syncMysqlMasterMiddlewareServer"
                                    ></LookPopup>
                                </el-form-item>
							</el-col>
						</el-form-item>

                        <el-form-item>
							<el-col :span="3" class="form-title"><span style="color: red">*</span> Mysql从数据库中间件：</el-col>
							<el-col :span="9">
								<el-form-item prop="MysqlSlaveMiddlewareServerID">
                                    <LookPopup v-model="dataSource.MysqlSlaveMiddlewareServerName"
                                        dataTitle="中间件服务器"
                                        dataUrl='omsapi/tenantserver/getlist'
                                        :dataOptions="{DisplayMiddlerwareType:200}"
                                        syncField='Name'
                                        @syncFieldEvent="syncMysqlSlaveMiddlewareServer"
                                    ></LookPopup>
                                </el-form-item>
							</el-col>
						</el-form-item>

                        <el-form-item>
							<el-col :span="3" class="form-title" ><span style="color: red">*</span> Mysql日志数据库中间件：</el-col>
							<el-col :span="9">
								<el-form-item prop="MysqlLogMiddlewareServerID">
                                    <LookPopup v-model="dataSource.MysqlLogMiddlewareServerName"
                                        dataTitle="中间件服务器"
                                        dataUrl='omsapi/tenantserver/getlist'
                                        :dataOptions="{DisplayMiddlerwareType:300}"
                                        syncField='Name'
                                        @syncFieldEvent="syncMysqlLogMiddlewareServerName"
                                    ></LookPopup>
                                </el-form-item>
							</el-col>
						</el-form-item> -->
                        
                        <!-- <el-form-item>
							<el-col :span="3" class="form-title" ><span style="color: red">*</span> Mongo日志数据库中间件：</el-col>
							<el-col :span="9">
								<el-form-item prop="MongoLogMiddlewareServerID">
                                    <el-select v-model="dataSource.MongoLogMiddlewareServerID" clearable filterable>
                                    <el-option v-for="item in dataSource.MongoLogMiddlewareServers" :key="item.MiddlewareServerID"
                                        :label="item.MiddlewareServerFriendlyName" :value="item.MiddlewareServerID">
                                    </el-option>
                                    </el-select>
                                </el-form-item>
							</el-col>
							<el-col :span="3" class="form-title"><span style="color: red">*</span> Mongo业务数据库中间件：</el-col>
							<el-col :span="9">
								<el-form-item prop="MongoBusinessMiddlewareServerID">
                                    <el-select v-model="dataSource.MongoBusinessMiddlewareServerID" clearable filterable>
                                    <el-option v-for="item in dataSource.MongoBusinessMiddlewareServers" :key="item.MiddlewareServerID"
                                        :label="item.MiddlewareServerFriendlyName" :value="item.MiddlewareServerID">
                                    </el-option>
                                    </el-select>
                                </el-form-item>
							</el-col>
						</el-form-item> -->

                        <el-form-item>
							<el-col :span="3" class="form-title" > 语言：</el-col>
							<el-col :span="9">
								<el-form-item prop="Lang">
                                    <DictionarySelect 
                                        v-model="dataSource.Lang" 
                                        dicTypeCode="SystemLanguageCode">
                                    </DictionarySelect>
                                </el-form-item>
							</el-col>
							<el-col :span="3" class="form-title"> 是否显示免责申明：</el-col>
							<el-col :span="9">
								<el-form-item prop="IsDisplayDisclaimer">
                                    <el-checkbox :size="'medium'" v-model="dataSource.IsDisplayDisclaimer"></el-checkbox>
                                </el-form-item>
							</el-col>
						</el-form-item>

                        <el-form-item>
							<el-col :span="3" class="form-title" ><span style="color: red">*</span> 状态：</el-col>
							<el-col :span="9">
								<el-form-item prop="TenantStatus">
                                    <DictionarySelect 
                                        v-model="dataSource.TenantStatus" 
                                        datType='Int' 
                                        dicTypeCode="CommonStatusCode">
                                    </DictionarySelect>
                                </el-form-item>
							</el-col>
						</el-form-item>
                        
						<el-form-item>
							<el-col :span="3" class="form-title">备注：</el-col>
							<el-col :span="21">
								<el-form-item class="singleLine" prop="Memo">
                                    <el-input v-model="dataSource.Memo" :maxlength="500" :placeholder="描述" type="textarea"
                                    :autosize="{minRows: 5, maxRows: 20}"></el-input>
                                    <span class="fixed-numer">{{(dataSource.Memo||'').length}}/500</span>
                                </el-form-item>
							</el-col>
						</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
    export default {
        data() {
            return {
                collapseItem: ['basicinfo','basicinfo1',''],
                baseCheckRule: {
                    TenantSystemName:[{ required: true, message: '不能为空', trigger: 'blur' }],
                    TenantName: [{ required: true, message: '不能为空', trigger: 'blur' }],
                    TenantProductName: [{ required: true, message: '不能为空', trigger: 'blur' }],
                    SystemVersion: [{ required: true, message: '不能为空', trigger: 'blur' }],
                    EffectiveDate: [{ required: true, message: '不能为空', trigger: 'blur' }],
                    ExpireDate: [{ required: true, message: '不能为空', trigger: 'blur' }],
                    Domain: [{ required: true, message: '不能为空', trigger: 'blur' }],
                    TenantStatus: [{ required: true, message: '不能为空', trigger: 'blur' }],
                },
                activeName:"tabbasic",
                dataSource:{
                },
                processStatusList:[],
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isAdd:false
            },
        },
        mounted: function () {
        },
        computed: {
            
        },
        watch: {
            'config.dataSourceKey':{
                handler(curVal, oldVal) {
                    if(curVal&&curVal>0){
                        if(curVal!=oldVal) 
                            this.initial(curVal); //初始化
                    }else{
                        this.add(); //新增初始化
                    }
                },
                deep: true
            }
        },
        methods: {
            //重置表单
            resetForm: function () {
                this.Utils.clearForm(this.dataSource);
            },
            back: function () {
                this.config.isDetailDisplay = false;
            },
            add: function () {
                this.initial();
                this.config.isAdd = true;
            },
            initial(id){
                var _this = this;
                _this.$ajax.send("omsapi/tenantsystem/get", "get", { id: id }, (data) => {
                    _this.dataSource=data.Result;
                    _this.$refs["basicForm"].clearValidate();
                });
            },
            save: function () {
                var _this = this;
                _this.$refs["basicForm"].validate((valid) => {
                    if (valid) {
                        _this.$ajax.send(
                            'omsapi/tenantsystem/save',
                            'post',
                            _this.dataSource,
                            (data) => {
                                _this.dataSource = data.Result;
                                _this.Event.$emit('reloadPageList', data.Result)
                                _this.Utils.messageBox('保存成功.', 'success')
                            }
                        )
                    } else {
                        return false
                    }
                });
            },
            pickerDisable: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            syncTenant:function(row){
                if(row){
                    this.dataSource.TenantId = row.OptEnterpriseID;
                    this.dataSource.TenantCode = row.EnterpriseCode;
                }
            },
            syncMysqlMasterMiddlewareServer:function(row){
                if(row){
                    this.dataSource.MysqlMasterMiddlewareServerID = row.Id;
                }
            },
            syncMysqlSlaveMiddlewareServer:function(row){
                if(row){
                    this.dataSource.MysqlSlaveMiddlewareServerID = row.Id;
                }
            },
            syncMysqlLogMiddlewareServerName:function(row){
                if(row){
                    this.dataSource.MysqlLogMiddlewareServerID = row.Id;
                }
            },
            syncTenantProduct:function(row){
                if(row){
                    this.dataSource.TenantProductId = row.Id;
                    this.dataSource.SystemType = row.SystemType;
                }
            },
            syncBasicTenantSystem:function(row){
                if(row){
                    this.dataSource.BasicTenantSystemId = row.Id;
                }
            },
            syncBasicDataTenantSystem:function(row){
                if(row){
                    this.dataSource.BasicDataTenantSystemId = row.Id;
                }
            },
        },
        components: {
        }
    }
</script>